import { CreateOrderConnectorProtocol } from '../../../infra/create-order-remote/create-order-remote.protocol'
import { Order } from '../../domain/order'

export class CreateOrderUseCase {
    constructor(
        private readonly createOrderRemote: CreateOrderConnectorProtocol
    ) {}

    async run(order: Order) {
        const response = await this.createOrderRemote.create(order)

        if (!response?.id) {
            throw new Error('Error when creating order')
        }

        return response.id
    }
}
