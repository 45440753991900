import React, { useState } from 'react'
import { TitleModel, TitleWhats, MsgError } from './style'
import InputNative from '../../../../../common/ui/input-native'
import { Buttons, Divisor, Modal, Select } from '../../../../../common/ui'
import { select } from '../../../signup/view/form/select/select-sector'
import { InputMaskEnum } from '../../../../../common/ui/input-mask/enum/InputMaskEnum'
import { Order } from '../../applications/domain/order'

type Props = {
    description: string
    setShowModalCategory: React.Dispatch<React.SetStateAction<boolean>>
    submitModal: (values: Order) => Promise<void>
    load: boolean
}

export const CategoryModal: React.FC<Props> = ({
    description,
    setShowModalCategory,
    submitModal,
    load,
}) => {
    const [sectorId, setSectorId] = useState<string>('')
    const [whatsApp, setWhatsApp] = useState<string>('')
    const [msgErrorInputCategory, setMsgErrorInputCategory] =
        useState<boolean>(false)

    const submit = async () => {
        if (!sectorId) {
            setMsgErrorInputCategory(true)
            return
        }

        await submitModal({
            description,
            sectorId,
            whatsApp,
        })
    }

    const onChangerSector = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setMsgErrorInputCategory(false)
        setSectorId(e.target.value)
    }

    return (
        <Modal setShowModal={setShowModalCategory}>
            <TitleModel>{description}</TitleModel>
            <Select
                label="Selecione o setor"
                name="sectorId"
                change={onChangerSector}
            >
                <option>Selecione</option>
                {select.options}
            </Select>

            {msgErrorInputCategory && <MsgError>Seleciona o setor</MsgError>}

            <Divisor sp="40px" />
            <TitleWhats>
                Por favor, forneça seu número do WhatsApp se deseja receber a
                lista de empresas que oferecem o que você precisa.
            </TitleWhats>
            <InputNative
                type="phone"
                name={'category-whats'}
                label="WhatsApp"
                required={false}
                onChange={e => setWhatsApp(e.target.value)}
                mask={InputMaskEnum.PHONE}
            />

            <Divisor sp="40px" />
            <Buttons.Common
                type="submit"
                label="Enviar"
                load={load}
                onClick={submit}
            />
        </Modal>
    )
}
