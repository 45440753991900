import {
    ButtonContainer,
    Copyright,
    FooterContainer,
    FooterContent,
    Links,
    LogoContainer,    
    TextContainer,
} from './style'

const Footer = () => {
    return (
        <FooterContainer>
            <FooterContent>
                <LogoContainer>
                    <img src="/imgs/logo.png" alt="Logotipo da Aítem" />
                </LogoContainer>
                <TextContainer>
                    <Copyright>
                        © Copyright 2024 - AíTem - Todos os direitos reservados
                        AíTem Online S.A.
                        <br />
                        CNPJ 45.660.157/0001-81 / Araxá - Minas Gerais - CEP:
                        38180001
                    </Copyright>
                    <Links>
                        <a href="/termos">Termos e condições de uso</a>
                    </Links>
                </TextContainer>
                <ButtonContainer>
                    <Copyright>WhatsApp: (034) 99811-7326</Copyright>
                </ButtonContainer>
            </FooterContent>
        </FooterContainer>
    )
}

export default Footer
