import { CreateOrderRemote } from "../../infra/create-order-remote/create-order-remote"
import { CreateOrderController } from "../controllers/create-order.controller"
import { CreateOrderStateProtocol } from "../protocols/create-order-state.protocol"
import { CreateOrderUseCase } from "../usecases/create-order/create-order.usecase"

export const createOrder = (state: CreateOrderStateProtocol) => {
  const connector = new CreateOrderRemote()
  const usecase = new CreateOrderUseCase(connector)
  return new CreateOrderController(usecase).submit(state)
}
