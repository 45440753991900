import styled from 'styled-components'

export const FooterContainer = styled.footer`
    background-color: #f8f8f8;
    border-top: 1px solid #e0e0e0;
    padding: 20px 0;
    font-family: Arial, sans-serif;
    margin-top: 80px;
`

export const FooterContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
`

export const LogoContainer = styled.div`
    flex: 1;

    img {
        height: 40px;
    }
`

export const TextContainer = styled.div`
    flex: 2;
    text-align: center;
`

export const Copyright = styled.p`
    font-size: 12px;
    color: #555;
    margin-bottom: 10px;
    line-height: 1.5;
`

export const Links = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;

    a {
        font-size: 12px;
        color: #007bff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`

export const ButtonContainer = styled.div`
    flex: 1;
    text-align: right;
`

export const RegisterButton = styled.button`
    background-color: #000;
    color: #fff;
    font-size: 12px;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;

    &:hover {
        background-color: #444;
    }
`
