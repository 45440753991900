import { Container, Header, Layout } from '../../../common/ui'
import { AboutWrap, TextAbout } from '../about/style'

const Terms = () => {
    return (
        <Layout>
            <Container>
                <Header />
                <AboutWrap>
                    <TextAbout>
                        <h1>
                            <b>Termos e Condições de Uso</b>
                        </h1>

                        <h2>
                            <b>Bem-vindo ao AíTem!</b>
                        </h2>
                        <p>
                            Os presentes Termos e Condições de Uso regem o
                            acesso e o uso da nossa plataforma. Ao utilizar
                            nosso site, você concorda com os termos descritos
                            abaixo. Caso não concorde com qualquer parte destes
                            Termos, não utilize o AíTem.
                        </p>

                        <h2>1. Sobre o AíTem</h2>
                        <p>
                            O AíTem é uma plataforma que conecta usuários a
                            produtos e serviços fornecidos por parceiros, de
                            forma rápida e eficaz. Não somos os fornecedores
                            diretos dos produtos e serviços, mas facilitamos a
                            conexão entre usuários e fornecedores parceiros.
                        </p>

                        <h2>2. Uso da Plataforma</h2>
                        <p>
                            Você deve ter pelo menos 18 anos de idade ou
                            permissão legal de um responsável para utilizar
                            nossa plataforma. Você concorda em utilizar o AíTem
                            apenas para fins lícitos e respeitando todas as leis
                            aplicáveis. Você é responsável pelas informações
                            fornecidas durante a utilização da plataforma e por
                            manter seus dados atualizados.
                        </p>

                        <h2>3. Política de Privacidade</h2>
                        <p>
                            O uso de dados pessoais está sujeito à nossa{' '}
                            <strong>Política de Privacidade</strong>, que está
                            disponível em nosso site. Garantimos o tratamento de
                            dados conforme as legislações vigentes, como a Lei
                            Geral de Proteção de Dados (LGPD). Não armazenamos
                            nenhuma informação, como cookies, dos usuários que
                            acessam nossa plataforma.
                        </p>

                        <h2>4. Responsabilidades do Usuário</h2>
                        <p>
                            O usuário é responsável por todas as ações
                            realizadas na plataforma. Você concorda em não
                            utilizar a plataforma para:
                        </p>
                        <ul>
                            <li>
                                Promover atividades fraudulentas ou ilícitas;
                            </li>
                            <li>
                                Publicar conteúdos que violem direitos de
                                terceiros, incluindo propriedade intelectual;
                            </li>
                            <li>Divulgar informações falsas ou enganosas.</li>
                        </ul>

                        <h2>5. Limitação de Responsabilidade</h2>
                        <p>
                            O AíTem não se responsabiliza por transações
                            realizadas entre usuários e fornecedores. A
                            plataforma não garante a disponibilidade ou a
                            qualidade dos produtos e serviços oferecidos pelos
                            parceiros. Não somos responsáveis por atrasos,
                            falhas ou problemas de desempenho decorrentes de
                            fatores fora do nosso controle.
                        </p>

                        <h2>6. Propriedade Intelectual</h2>
                        <p>
                            Todos os direitos relacionados ao design,
                            funcionalidade e conteúdo da plataforma são de
                            propriedade do AíTem ou de seus licenciadores. Você
                            não pode copiar, reproduzir ou utilizar qualquer
                            parte da plataforma sem autorização prévia.
                        </p>

                        <h2>7. Política Empresarial do WhatsApp e Meta</h2>
                        <p>
                            Como utilizamos integrações com o WhatsApp, seguimos
                            as diretrizes da{' '}
                            <strong>Política Empresarial do WhatsApp</strong>.
                            Você também deve respeitar essas políticas ao
                            utilizar nossa plataforma, o que inclui:
                        </p>
                        <ul>
                            <li>
                                Fornecer informações claras e precisas sobre
                                seus produtos ou serviços;
                            </li>
                            <li>
                                Não utilizar o WhatsApp para envio de mensagens
                                indesejadas ou spam;
                            </li>
                            <li>
                                Garantir que o site ou conteúdo relacionado às
                                suas solicitações estão em conformidade com as
                                diretrizes do Meta.
                            </li>
                        </ul>

                        <h2>8. Suspensão e Encerramento de Conta</h2>
                        <p>
                            O AíTem reserva-se o direito de suspender ou
                            encerrar o acesso do usuário em casos de violação
                            destes Termos ou de condutas que possam prejudicar o
                            funcionamento da plataforma ou a experiência de
                            outros usuários.
                        </p>

                        <h2>9. Modificações nos Termos</h2>
                        <p>
                            Podemos alterar estes Termos a qualquer momento, com
                            notificação prévia aos usuários. O uso continuado da
                            plataforma após as modificações implica na aceitação
                            dos novos Termos.
                        </p>

                        <h2>10. Lei Aplicável e Foro</h2>
                        <p>
                            Estes Termos são regidos pelas leis brasileiras.
                            Qualquer disputa será resolvida no foro da comarca
                            de domicílio do usuário.
                        </p>

                        <h2>Contato</h2>
                        <p>
                            Caso tenha dúvidas sobre estes Termos, entre em
                            contato conosco pelo e-mail{' '}
                            <a href="mailto:rafaelalveriano@gmail.com">
                                rafaelalveriano@gmail.com
                            </a>
                            .
                        </p>
                    </TextAbout>
                </AboutWrap>
            </Container>
        </Layout>
    )
}

export default Terms
