import React, { useState } from 'react'
import {
    Wrap,
    WrapInputSearch,
    InputSearch,
    InputSearchIcon,
    Container,
    InputButtonDesktop,
    InputButtonMob,
    MsgError,
} from './style'

type Props = {
    submit: (value: string) => void
    required?: boolean
}

const InputSearchHome: React.FC<Props> = ({ submit, required }) => {
    const [inputValue, setInputValue] = useState<string>('')
    const [showError, setShowError] = useState<boolean>(false)

    const onClick = () => {
        if (required && !inputValue) {
            setShowError(true)
            return
        }

        submit(inputValue)
    }

    const onChangeInput = (e: any) => {
        const value = e.target.value
        setInputValue(value)
        if (value.length > 1) {
            setShowError(false)
        }
    }

    return (
        <Wrap>
            <Container>
                <WrapInputSearch>
                    <InputSearchIcon />
                    <InputSearch
                        value={inputValue}
                        onChange={onChangeInput}
                        placeholder="conte-nos o que você precisa"
                    />
                </WrapInputSearch>

                <InputButtonDesktop onClick={onClick}>
                    Aí tem?
                </InputButtonDesktop>
                <InputButtonMob onClick={onClick} />
            </Container>
            {showError && (
                <MsgError>Conte-nos o que você precisa no campo acima</MsgError>
            )}
        </Wrap>
    )
}

export { InputSearchHome }
