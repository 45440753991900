import { Children } from 'react'
import Background from '../background'
import Footer from '../footer'

interface Props {
    children: any
    color?: string
}

const Layout: React.FC<Props> = ({ children, color }) => {
    return (
        <>
            <Background color={color} />
            {Children.map(children, child => child)}
            <Footer />
        </>
    )
}

export default Layout
