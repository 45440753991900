import { Container, Header, Layout } from '../../../../common/ui'
import { TopBanner } from './top-banner'
import { InputSearchHome } from './input-search'
import { useState } from 'react'
import { CategoryModal } from './category-modal'
import { Order } from '../applications/domain/order'
import { createOrder } from '../applications/factories/create-order.factory'
import { useAlertShow } from '../../../../providers/alert/context/alert.context.dispatcher'

const Home = () => {
    const [showModalCategory, setShowModalCategory] = useState<boolean>(false)
    const [inputValue, setInputValue] = useState<string>('')
    const [loadModal, setLoadModal] = useState<boolean>(false)
    const { showAlert } = useAlertShow()

    const iputSearchOnClick = (value: string) => {
        setInputValue(value)
        setShowModalCategory(true)
    }

    const submitModal = async (order: Order) =>
        createOrder({ order, setLoadModal, showAlert })

    return (
        <Layout>
            <Container>
                {showModalCategory === true && (
                    <CategoryModal
                        submitModal={submitModal}
                        description={inputValue}
                        setShowModalCategory={setShowModalCategory}
                        load={loadModal}
                    />
                )}
                <Header />
                <TopBanner />
                <InputSearchHome submit={iputSearchOnClick} required />
            </Container>
        </Layout>
    )
}
export default Home
