import { BrowserRouter, Route, Routes } from 'react-router-dom'
import * as Site from '../../ui/site/'
import * as Dash from '../../ui/dash/'

const Routers = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Site.Home />} />
            <Route path="/cadastro" element={<Site.Signup />} />
            <Route path="/como-funciona" element={<Site.About />} />
            <Route path="/login" element={<Site.Login />} />
            <Route path="/solicitacao/:id" element={<Site.Solicitation />} />
            <Route path="/dash" element={<Dash.Orders />} />
            <Route path="/termos" element={<Site.Terms />} />

            <Route path="*" element={<Site.NotFoundError />} />
        </Routes>
    </BrowserRouter>
)

export default Routers
